* {
    font-family: 'Inter', sans-serif;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 4px 4px 0px #0000001A;
    padding: 20px 40px;
    z-index: 10;
    background-color: white;
}

.headline {
    font-size: 20px;
    font-weight: 400;
}

.profileContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.logoMobile {
    display: none;
}

.profileContainer>img {
    cursor: pointer;
}

.profileContainer>img:hover {
    filter: drop-shadow(5px 5px 10px #919191);
}

.initials {
    cursor: pointer;
    border-radius: 100%;
    border: 3px solid #2A3647;
    color: #29ABE2;
    width: 56px;
    height: 56px;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .125s ease-in-out;
}

.initials:hover {
    background-color: lightgray;
}

.dialog {
    top: 96px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    border-radius: 20px 0 20px 20px;
    background: #2A3647;
    padding: 10px;
    position: absolute;
    right: 24px;
}

.link {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    color: #CDCDCD;
    font-size: 16px;
    transition: all .125s ease-in-out;
    width: 100%;
}

.helpLink {
    display: none;
}

.link:hover {
    background-color: #2A3D59;
    color: white;
}

.extraLink {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 740px) {
    .logoMobile {
        display: flex;
        width: 32px;
    }

    .headline {
        display: none;
    }

    .header {
        padding: 20px 48px;
    }

    .profileContainer > img {
        display: none;
    }

    .initials {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
    
    .helpLink {
        display: block;
    }

    .dialog {
        top: 80px;
    }
}

@media (max-width: 450px) {
    .header {
        padding: 20px 16px;
    }
}