.addTaskContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 32px;
    position: relative;
    overflow-y: hidden;
}

.headline {
    align-self: flex-start;
}

.dropdownMenu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 12px 0;
    border: 1px solid #D1D1D1;
    border-top: 1px solid transparent;
    border-radius: 0 0 10px 10px;
    font-size: 20px;
    background-color: white;
    height: 196px;
    overflow: auto;
    position: absolute;
    top: 80px;
    z-index: 1;
}

.dropwArrow {
    position: absolute;
    bottom: 20px;
}

.contactBox {
    cursor: pointer;
    padding: 12px 21px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
}

.contactBox:hover {
    background-color: #D1D1D1;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    color: white;
    font-size: 16px;
}

.selectedContacts {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.extra {
    cursor: pointer;
    background-color: #2A3647;
}

.extraContacts {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: #2A3647;
    padding: 12px 18px;
    border-radius: 10px 10px 10px 0;
    position: absolute;
    bottom: 40px;
    left: 224px;
    opacity: 0;
    transition: all .125s ease-in-out;
}

.opacity100 {
    opacity: 1;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
}

.formFields {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 32px;
}

.leftside,
.rightside {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.seperator {
    width: 1px;
    height: 385px;
    background-color: #D1D1D1;
}

.prioBtnContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.prioBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
    padding: 16px 10px;
    gap: 8px;
    box-shadow: 0px 0px 4px 0px #0000001A;
    font-size: 20px;
    width: calc((100% - 16px) / 3)
}

.selectedHigh {
    background-color: #FF3D00;
    color: white;
    font-size: 21px;
    font-weight: 700;
}

.selectedLow {
    background-color: #7AE229;
    color: white;
    font-size: 21px;
    font-weight: 700;
}

.selectedMedium {
    background-color: #FFA800;
    color: white;
    font-size: 21px;
    font-weight: 700;
}

.selectedHigh>img,
.selectedLow>img,
.selectedMedium>img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(91%) saturate(1%) hue-rotate(198deg) brightness(106%) contrast(100%);
}

.category {
    height: fit-content;
}

.category>div {
    cursor: pointer;
    padding: 12px 21px;
    border-radius: 8px;
}

.category>div:hover {
    background-color: #D1D1D1;
}

.label {
    font-size: 20px;
    margin-bottom: 8px;
}

.required {
    color: red;
}

.actionField {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.requiredContainer {
    font-size: 20px;
    align-self: flex-start;
}

.clearBtn {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2A3647;
    border-radius: 10px;
    color: #2A3647;
    gap: 4px;
    font-size: 21px;
}

.actionBtnField {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: flex-end;
}

.clearBtn:hover {

    border-color: #29ABE2;
    color: #29ABE2;
}

.clearBtn:hover>img {
    filter: brightness(0) saturate(100%) invert(66%) sepia(16%) saturate(6658%) hue-rotate(165deg) brightness(94%) contrast(89%);
}

.submitBtn {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: #2A3647;
    color: white;
    gap: 4px;
    font-size: 21px;
}

.submitBtn:hover {
    background-color: #29ABE2;
}

.addIcon {
    cursor: pointer;
    position: absolute;
    bottom: 17px;
    right: 21px;
    transition: all .125s ease-in-out;
}

.addIconContainer {
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    position: absolute;
    right: 21px;
    bottom: 16px;
}

.icon {
    cursor: pointer;
    transition: all .125s ease-in-out;
}

.icon:hover,
.addIcon:hover {
    filter: drop-shadow(5px 5px 10px #646262);
}

.seperatorSmall {
    width: 1px;
    height: 21px;
    background-color: #D1D1D1;
}

.styledList {
    padding-left: 21px;
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    width: 100%;
}

.styledList>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;
    border-radius: 8px;
    position: relative;
    transition: all .125s ease-in-out;
}

.styledList>li:hover {
    cursor: pointer;
    background-color: #EEEEEE;
}

.editSubtaskContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    opacity: 0;
    transition: all .125s ease-in-out;
}

.styledList>li:hover .editSubtaskContainer {
    opacity: 1;
}

.bullet {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background-color: black;
    margin-right: 10px;
}

.content {
    flex: 1;
}

.disabledBtn {
    cursor: unset;
    background-color: #646262;
}

.disabledBtn:hover {
    background-color: #646262;
}

.taskCreated {
    display: flex;
    align-items: center;
    gap: 48px;
    background-color: #2A3647;
    color: white;
    font-weight: 500;
    font-size: 21px;
    padding: 12px 21px;
    border-radius: 12px;
    position: absolute;
    top: 200%;
    transition: all .125s ease-in-out;
}

.created {
    top: 50%;
}

.formFields::-webkit-scrollbar {
    height: 1px;
    width: 1px;
}

/* .formFields::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
}

.formFields::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

.formFields::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

.formFields::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #CBCCD3;
}

.formFields::-webkit-scrollbar-thumb:hover {
    background-color: #CBCCD3;
}

.formFields::-webkit-scrollbar-thumb:active {
    background-color: #CBCCD3;
} */

.addTaskContent::-webkit-scrollbar {
    display: none;
}

.addTaskContent {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.createdOverlay {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 500;
}

.createdMessage {
    background-color: #2A3647;
    border-radius: 10px;
    font-weight: 700;
    color: white;
    font-size: 21px;
    text-align: center;
    padding: 15px 21px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1240px) {

    .selectedHigh,
    .selectedLow,
    .selectedMedium,
    .prioBtn {
        font-size: 16px;
    }
}

@media (max-width: 1045px) {
    .prioBtn {
        gap: 4px;
    }

    .prioBtn>img,
    .selectedHigh>img,
    .selectedLow>img,
    .selectedMedium>img {
        width: 14px;
    }

}

@media (max-width: 960px) {
    .headline {
        background-color: #F6F7F8;
        z-index: 10;
        width: 100%;
        position: sticky;
        top: 0;
    }

    .seperator {
        display: none;
    }

    .addTaskContent {
        justify-content: flex-start;
        overflow: auto;
    }

    .formFields {
        flex-direction: column;
        gap: 18px;
    }

    .form {
        padding-bottom: 0;
        justify-content: space-between;
        height: auto;
        gap: 24px;
    }

    .formFields {
        justify-content: flex-start;
        padding-bottom: 0;
    }

    .leftside,
    .rightside {
        flex: unset;
        gap: 20px
    }

    .requiredContainer {
        align-self: center;
    }

    .taskCreated {
        display: none;
    }
}

@media (max-width: 836px) {

    .submitBtn,
    .clearBtn {
        padding: 16px 12px;
        font-size: 18px;
    }

    .actionBtnField {
        gap: 8px;
    }

    .requiredContainer {
        font-size: 16px;
    }
}

@media (max-width: 740px) {
    .actionBtnField {
        gap: 16px;
    }

    .headline {
        font-size: 47px;
    }

    .form {
        padding-bottom: 48px;
    }

    .createdOverlay {
        display: flex;
    }
}

@media (max-width: 630px) {
    .clearBtn {
        display: none
    }

    .addTaskContent {
        gap: 16px;
    }

    .requiredContainer {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .submitBtn {
        padding: 12px 10px;
        font-size: 18px;
    }

    .contactBox {
        padding: 6px 21px;
    }

    .label {
        font-size: 16px;
    }

    .addIcon {
        bottom: 13px;
    }

    .dropdownMenu {
        top: 67px;
    }
    
    .addIconContainer {
        bottom: 9px;
    }

    .form {
        padding-bottom: 64px;
    }

    .category > div {
        padding: 6px 21px;
        font-size: 16px;
    }

}

@media (max-width: 390px) {
    .requiredContainer {
        font-size: 12px;
    }

    .prioBtn {
        font-size: 12px;
    }

    .initials {
        font-size: 12px;
        width: 32px;
        height: 32px;
    }

    .extraContacts {
        padding: 6px 12px;
        bottom: 32px;
        left: 178px;
    }
}

@media (max-width: 380px) and (max-height: 670px) {}