.main {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding-bottom: 12px;
    background-color: #F6F7F8;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 80px 77px 0;
}

.signupContainer {
    justify-content: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 48px 88px;
    gap: 32px;
    border-radius: 30px;
    box-shadow: 0px 0px 14px 3px #0000000A;
    overflow: hidden;
}

.headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.headline>h1 {
    font-size: 61px;
}

.seperator {
    height: 3px;
    background-color: #29ABE2;
    width: 80%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.privacyLink {
    color: #29ABE2;
}

.privacyLink:hover {
    text-decoration: underline;
}

.actionContainer {
    align-self: center;
}

.successBox {
    position: absolute;
    text-align: center;
    box-shadow: 0px 0px 4px 0px #0000001A;
    border-radius: 20px;
    padding: 15px 25px;
    background-color: #2a3647;
    color: white;
    font-size: 20px;
    animation: slideIn forwards ease-in-out .125s;
}

@keyframes slideIn {
    from {
        transform: translateY(400px);
    }

    to {
        transform: translateY(0);
    }
}

/* Media Queries */

@media (max-height: 890px) {
    .header {
        padding: 40px 77px 0;
    }

    .headline>h1 {
        font-size: 48px;
    }

    .signupContainer {
        padding: 32px 64px;
        gap: 18px;
    }
}

@media (max-height: 830px) {
    .header {
        padding: 32px 77px 0;
    }

    .headline>h1 {
        font-size: 40px;
    }

    .signupContainer {
        padding: 24px 56px;
    }
}

@media (max-width: 790px) {
    .header {
        padding: 32px 32px 0 !important;
    }

    .header>img {
        width: 80px;
    }

    .main {
        gap: 24px;
    }
}

@media (max-width: 505px) {
    .header {
        padding: 24px !important;
    }

    .headline > h1 {
        font-size: 48px;
    }

    .header>img {
        width: 64px;
        height: 78.03px
    }
}

@media (max-width: 445px) {
    .signupContainer {
        width: 95%;
        padding: 24px 24px 24px 24px !important;
        gaP: 12px;
    }

    .form {
        width: 100%;
    }

    .header {
        padding: 24px 24px 0 !important;
    }
}

/* Height */