.contactContent {
    display: flex;
    height: 100%;
    background-color: blanchedalmond;
    width: 100%;
}

.sidebar {
    width: 33%;
    min-width: 456px;
    display: flex;
    flex-direction: column;
    height: calc(100svh - 96px);
    background-color: white;
    padding: 32px 24px 32px 48px;
    overflow: auto;
}

.contactPreview {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    flex: 1;
    background-color: #F6F7F8;
    padding: 72px 48px;
    gap: 32px;
    overflow-x: hidden;
}

.backArrow {
    cursor: pointer;
    position: absolute;
    top: 48px;
    right: 48px;
    display: none;
    align-items: center;
    justify-content: center;
    transition: all .125s ease-in-out;
    border-radius: 100%;
}

.backArrow:hover {
    background-color: #D1D1D1;
}

.addContactBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 21px;
    font-weight: 700;
    border: 1px solid #2A3647;
    background-color: #2A3647;
    padding: 12px 0;
    border-radius: 10px;
    color: white;
    transition: all .125s ease-in-out;
}

.addContactBtn:hover {
    background-color: #29ABE2;
    border: 1px solid #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190, 191, 192, 0.75);
}

.addContactBtnMobile {
    cursor: pointer;
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #2A3647;
    color: white;
    position: absolute;
    bottom: 48px;
    right: 48px;
    padding: 12px;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: all .125s ease-in-out;
}

.addContactBtnMobile:hover {
    background-color: #29ABE2;
}

.contactList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.letterContainer {
    padding: 17px 36px;
    font-size: 21px;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: #D1D1D1;
}

.contactButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 35px;
    border-radius: 10px;
    padding: 15px 24px;
    transition: all .125s ease-in-out;
    width: 100%;
}

.contactButton:hover {
    background-color: #D1D1D1;
}

.initials {
    border: 2px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    font-size: 12px;
    border-radius: 100%;
    color: white;
}

.headline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    align-self: flex-start;
    width: 100%;
    height: 74px;
}

h1 {
    font-size: 61px;
}

.seperatorHeadline {
    display: flex;
    width: 3px;
    height: 80%;
    background-color: #29ABE2;
}

.seperatorHeadlineMobile {
    display: none;
    height: 3px;
    width: 120px;
    background-color: #29ABE2;
    border-radius: 4px;
}

.headline>span {
    font-size: 27px;
    color: #2A3647;
}

.contactDetail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    transition: all .125s ease-in-out;
    flex: 1;
}

.slideInAnimation {
    animation: slideIn .125s 1s ease-in-out forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(1500px);
    }

    to {
        transform: translateX(0);
    }
}

.contactTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 54px;
}

.initialsDetail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    font-size: 47px;
    font-weight: 500;
    border: 3px solid #FFFFFF;
    border-radius: 100%;
    color: white;
}

.nameBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 47px;
    font-weight: 500;
    flex: 1;
}

.editBox {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 24px;
}

.editBox>span {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 13px;
}

.editBox>span:hover,
.editBox>span:hover .editBox>span svg {
    color: #29ABE2;
    fill: #29ABE2;
}

.editBox>span svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
}

.editBtn {
    cursor: pointer;
    border-radius: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: #2A3647;
    padding: 12px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    right: 16px;
    width: 45px;
    height: 45px;
}

.editBtn:hover {
    background-color: #29ABE2;

}

.editMenu {
    display: none;
    align-items: flex-start;
    flex-direction: column;
    padding: 12px 21px;
    background-color: white;
    border-radius: 15px 15px 0 15px;
    box-shadow: 0px 4px 4px 0px #00000040;
    gap: 12px;
    position: absolute;
    right: 4px;
    bottom: 32px;
}

.editMenu>span {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.editMenu>span:hover {
    color: #29ABE2;
}

.editMenu>span:hover img {
    filter: invert(66%) sepia(6%) saturate(7254%) hue-rotate(166deg) brightness(89%) contrast(98%);
}

.information {
    font-size: 20px;
}

.emailContainer {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.emailBox {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.emailLink {
    color: #29ABE2;
}

.contactCreatedBox {
    display: flex;
    background-color: #2A3647;
    color: white;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px #00000026;
    transition: transform .125s ease-in-out;
    transform: translateX(500%);
}

.slideIn {
    transform: translateX(0);
}


.dialogContainer {
    display: flex;
    transition: transform 0.25s ease-in-out;
    transform: translateX(300%)
}

.dialogContainer.show {
    transform: translateX(0);
}

.leftside {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    padding: 66px 48px;
    color: white;
    background-color: #2A3647;
    border-radius: 30px 0 0 30px;
}

.addContactContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.addContactContent>span {
    font-size: 27px;
}

.dialogSeperator {
    width: 90px;
    background-color: #29ABE2;
    height: 3px;
}

.rightside {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 48px;
    padding: 72px 40px 72px 48px;
    border-radius: 0 30px 30px 0;
}

.imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    background-color: #d1d1d1;
    border-radius: 100%;
    border: 3px solid #FFFFFF;
}

.imgContainer>img {
    object-fit: cover;
}

.addContactForm {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.formAction {
    display: flex;
    gap: 24px;
    align-items: center;
}

.cancelBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid #2A3647;
    border-radius: 10px;
    padding: 16px;
    color: #2A3647;
    font-size: 20px;
}

.deleteBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    border: 1px solid #2A3647;
    border-radius: 10px;
    padding: 16px;
    color: #2A3647;
    font-size: 20px;
}

.cancelBtn:hover,
.deleteBtn:hover {
    border: 1px solid #29ABE2;
    color: #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190, 191, 192, 0.75);
}

.cancelBtn:hover svg,
.deleteBtn:hover svg {
    stroke: #29ABE2;
    color: #29ABE2;
}

.submitBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 10px;
    padding: 16px;
    background-color: #2A3647;
    color: white;
    font-size: 20px;
    font-weight: 700;
}

.submitBtn:hover {
    background-color: #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190, 191, 192, 0.75);
}

.cancelIcon {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 32px;
    right: 32px;
}

.cancelIconMobile {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    position: absolute;
    top: 32px;
    right: 32px;
}

.cancelIcon:hover svg,
.cancelIconMobile:hover svg {
    stroke: #29ABE2;
    color: #29ABE2;
}

/* Media Queries*/

/* MAX HEIGHT */

@media (max-height: 800px) {
    .contactPreview {
        padding: 48px;
    }
}

@media (max-height: 750px) {
    .contactPreview {
        gap: 18px;
        padding: 32px 48px;
    }
}

@media (max-width: 1280px) {
    .contactTop {
        gap: 20px;
    }

    .headline {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        height: auto;
    }

    .seperatorHeadline {
        display: none;
    }

    .seperatorHeadlineMobile {
        display: block;
    }

    .contactDetail {
        gap: 24px;
    }
}

@media (max-width: 1130px) {
    .initialsDetail {
        width: 96px;
        height: 96px;
        font-size: 40px;
    }

    .nameBox {
        font-size: 40px;
    }

    .rightside {
        padding: 72px 32px;
        gap: 32px;
    }

    .leftside {
        padding: 66px 32px;
    }

    .addContactForm {
        flex: 1;
    }
}

@media (max-width: 1060px) {
    .backArrow {
        display: flex;
    }

    .formAction {
        flex-direction: column;
        align-items: flex-end;
    }

    .cancelBtn,
    .submitBtn {
        width: 100%;
    }
}

@media (max-width: 860px) {
    .dialogContainer {
        flex-direction: column;
    }

    .leftside {
        border-radius: 30px 30px 0 0;
    }

    .leftside>img {
        display: none;
    }

    .addContactContent>h1 {
        font-size: 47px;
    }

    .addContactContent>span {
        font-size: 20px;
    }

    .rightside {
        border-radius: 0 0 30px 30px;
        flex-direction: column;
        gap: 18px;
    }

    .imgContainer {
        margin-top: -128px;
    }

    .form {
        gap: 18px;
    }

    .formAction {
        flex-direction: row;
        justify-content: center;
    }

    .cancelBtn {
        display: none;
    }

    .submitBtn {
        width: 75%;
    }

    .cancelIcon {
        display: none;
    }

    .cancelIconMobile {
        display: block;
    }
}

@media (max-width: 800px) {
    .addContactBtnMobile {
        display: flex;
    }

    .addContactBtn {
        display: none;
    }
}

@media (max-width: 740px) {
    .addContactBtnMobile {
        bottom: 120px;
        right: 32px;
    }

    .sidebar {
        padding: 32px 48px 72px 48px;
    }

    .contactPreview {
        min-height: calc(100svh - 160px)
    }
}

@media (max-width: 500px) {
    

    .headline {
        gap: 8px;
    }

    .headline>h1 {
        font-size: 47px;
    }

    .headline>span {
        font-size: 20px
    }

    .initialsDetail {
        width: 80px;
        height: 80px;
        font-size: 27px;
    }

    .nameBox {
        font-size: 36px;
    }

    .editBox {
        display: none;
    }

    .editMenu {
        display: flex;
    }

    .editBtn {
        display: flex;
    }

    .contactDetail {
        gap: 21px;
    }
}

@media (max-width: 450px) {
    .backArrow {
        right: 24px;
        top: 24px;
    }

    .contactPreview {
        padding: 20px 16px;
    }

    .sidebar {
        padding: 20px 16px 72px 16px;
        min-width: auto;

    }

    .addContactBtnMobile {
        right: 16px;
        bottom: 104px;
    }
}

@media (max-width: 410px) {
    .rightside {
        padding: 72px 24px 24px 24px;
    }

    .form {
        width: 100%;
    }
}