.BoardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.headline {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.searchContainer {
    display: flex;
    align-items: center;
    gap: 32px;
}

.addTaskBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 11px 18px;
    border-radius: 8px;
    background-color: #2A3647;
    color: white;
    border: 1px solid transparent;
    font-size: 21px;
    font-weight: 700;
}

.popupContent {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 30px;
    padding: 48px 40px;
    transform: translateX(250%);
    transition: all .125s ease-in-out;
    transition-delay: .5s;
    justify-content: center;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    top: 48px;
    right: 40px;
    border-radius: 100%;
}

.closeIcon:hover {
    background-color: #eeeeee;
}

.columnContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    min-height: 252px;
    width: 100%;
}

.taskColumn {
    display: flex;
    flex-direction: column;
    width: 252px;
    gap: 24px;
    padding-bottom: 32px;
    
}

.columnHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.columnHeader>h3 {
    color: #42526E;
    font-size: 20px;
    font-weight: 700;
}

.addTaskIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
    border-radius: 8px;
    border: 2px solid #2A3647;
    transition: all .125s ease-in-out;
}

.addTaskIcon:hover {
    filter: invert(72%) sepia(48%) saturate(4565%) hue-rotate(165deg) brightness(93%) contrast(90%);
}

.task {
    position: relative;
    cursor:pointer;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 0px 10px 3px #00000014;
    width: 100%;
    transition: transform .125s ease-in-out;
}

.task:hover {
    transform: scale(1.05);
}

.category {
    background-color: #0038FF;
    padding: 4px 16px;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    color: white;
    width: fit-content;
}

.task>h4 {
    color: #2A3647;
    font-size: 16px;
    font-weight: 700;
}

.titleDescription {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.titleDescription > h4 {
    word-wrap: break-word;
}

.description {
    font-size: 16px;
    font-weight: 400;
    color: #A8A8A8;
}

.subtasksProgress {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.progressbar {
    background-color: #F4F4F4;
    border-radius: 8px;
    width: 128px;
    height: 8px;
    position: relative;
}

.progressbarFilled {
    background-color: #4589FF;
    height: 8px;
    position: absolute;
    left: 0;
    width: 66%;
    border-radius: 8px;
}

.subtasksProgress>span {
    font-size: 12px;
}

.contactPriorityContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contactIcons {
    display: flex;
    align-items: center;
}

.contactIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: white;
    border: 1px solid #FFFFFF;
    border-radius: 100%;
    margin-left: -6px;
}

.initials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: white;
    border: 1px solid #FFFFFF;
    border-radius: 100%;
}

.tasks {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.extra {
    cursor: pointer;
    background-color: #2A3647;
    margin-left: -6px;
}

.extraContacts {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: #2A3647;
    padding: 12px 18px;
    border-radius: 10px 10px 10px 0;
    position: absolute;
    bottom: 40px;
    left: 152px;
    opacity: 0;
    transition: all .125s ease-in-out;
}

.opacity100 {
    opacity: 1;
}

.taskDetailView {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 350px;
    min-height: 600px;
    height: 100%;
}

.taskDetailHeadline {
    font-size: 61px;
    font-weight: 700;
}

.taskDetailView>span {
    font-size: 20px;
    max-height: 72px;
    overflow: auto;
}

.dateAndPrioContainer {
    display: flex;
    align-items: center;
    font-size: 20px;
    gap: 24px;
}

.dateAndPrioContainer>span {
    display: flex;
    align-items: center;
    gap: 18px;
}

.assignedContactsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.assignedContactsContainer>span {
    font-size: 20px;
}

.contactsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 136px;
    overflow: auto;
}

.contactBox {
    display: flex;
    align-items: center;
    padding: 7px 16px;
    gap: 24px;
}

.contactName {
    font-size: 20px;
    max-width: 80%;
    word-wrap: break-word;
}

.subtaskContainer {
    font-size: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 136px;
    overflow-y: auto;
    overflow-x: hidden;
}

.subtaskBox {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.subtaskBox > label {
    width: 100%;
    word-wrap: break-word;
}

.actionContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
}

.actionButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 20px;
}

.actionButton:hover {
    color: #007CEE;
}

.actionButton:hover>img {
    filter: invert(51%) sepia(85%) saturate(1875%) hue-rotate(157deg) brightness(89%) contrast(101%);
}



/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #CBCCD3 #DFE9EB;
}*/

/* Chrome, Edge and Safari */
.contactsContainer::-webkit-scrollbar,
.subtaskContainer::-webkit-scrollbar,
.taskDetailView>span::-webkit-scrollbar,
.formEditor::-webkit-scrollbar {
    height: 1px;
    width: 1px;
}

.contactsContainer::-webkit-scrollbar-track,
.subtaskContainer::-webkit-scrollbar-track,
.taskDetailView>span::-webkit-scrollbar-track,
.formEditor::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
}

.contactsContainer::-webkit-scrollbar-track:hover,
.subtaskContainer::-webkit-scrollbar-track:hover,
.taskDetailView>span::-webkit-scrollbar-track:hover,
.formEditor::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

.contactsContainer::-webkit-scrollbar-track:active,
.subtaskContainer::-webkit-scrollbar-track:active,
.taskDetailView>span::-webkit-scrollbar-track:active,
.formEditor::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

.contactsContainer::-webkit-scrollbar-thumb,
.subtaskContainer::-webkit-scrollbar-thumb,
.taskDetailView>span::-webkit-scrollbar-thumb,
.formEditor::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #CBCCD3;
}

.contactsContainer::-webkit-scrollbar-thumb:hover,
.subtaskContainer::-webkit-scrollbar-thumb:hover,
.taskDetailView>span::-webkit-scrollbar-thumb:hover,
.formEditor::-webkit-scrollbar-thumb:hover {
    background-color: #CBCCD3;
}

.contactsContainer::-webkit-scrollbar-thumb:active,
.subtaskContainer::-webkit-scrollbar-thumb:active,
.taskDetailView>span::-webkit-scrollbar-thumb:active,
.formEditor::-webkit-scrollbar-thumb:active {
    background-color: #CBCCD3;
}

.taskEditor {
    display: flex;
    align-items: flex-start;
    width: 525px;
}

.formEditor {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 600px;
    gap: 18px;
    overflow: auto;
    margin: 40px 0px 0 ;
    padding: 0 24px 0 0;
}

.prioContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.prioBtnContainer {
    display: flex;
    align-items: center;
    gap: 18px;
}

.submitBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 24px;
    border-radius: 8px;
    background-color: #2A3647;
    color: white;
    border: 1px solid transparent;
    font-size: 21px;
    font-weight: 700;
    width: fit-content;
    align-self: flex-end;
}

.submitBtn>img {
    width: 16px;
}

.submitBtn:hover {
    background-color: #007CEE;
}

.disabled {
    cursor: unset;
    background-color: grey;
}

.NoTasksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 15px 25px;
    width: 100%;
    border: 1px dashed #A8A8A8;
    background-color: #E7E7E7;
    box-shadow: 0px 0px 4px 0px #00000029;
    color: #A8A8A8;
}

.dragging {
    background-color: #E7E7E7;
    border-radius: 10px;
    border: 1px dashed #A8A8A8;
    transform: scale(1.05);
    transition: transform .125s ease-in-out;
}

.dragMobile {
    animation: wiggle .250s ease-in-out infinite
}

@keyframes wiggle {
    from {
        transform: rotate(-2deg);
    }
    to {
        transform: rotate(2deg);
    }
}

.addTaskMobile {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #2A3647;
    border-radius: 10px;
    padding: 3px;
    width: 40px;
    height: 40px;
}

.headlineWrapper {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mainDetail {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    justify-content: space-between;
    width: 100%;
    gap: 18px;
}

.mainDetail > h2,
.mainDetail > span {
    word-wrap: break-word;
}

/* Media Queries*/

@media (max-height: 900px) {
    .taskDetailView {
        gap: 16px;
    }

    .taskDetailHeadline {
        font-size: 48px;
    }

    .contactsContainer {
        gap: 4px;
    }
}

@media (max-width: 1050px) {
    .headline {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px
    }

    .searchContainer {
        width: 100%;
    }

    .searchInput {
        flex: 1;
    }
}

@media (max-width: 960px) {
    .popupContent {
        width: 65%;
        height: 85%;
        justify-content: flex-start;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .popupContent::-webkit-scrollbar {
        display: none;
    }

    .taskDetailView,
    .taskEditor {
        width: auto;
    }
    
}




@media (max-width: 848px) {
    .columnContainer {
        justify-content: center;
    }
}

@media (max-width: 800px) {
    .popupContent {
        width: 75%
    }
}

@media (max-width: 740px) {
    .columnContainer {
        justify-content: space-between;
    }

    .BoardContent {
        margin-bottom: 252px;
    }

    .addTaskBtn {
        display: none;
    }

    .addTaskMobile {
        display: flex;
    }

    .headline > h1 {
        display: none;
    }

    .headlineWrapper {
        display: flex;
    }

    .headlineWrapper > h1 {
        font-size: 47px;
    }

    .popupContent {
        width: 95%;
        height: 75%;
    }
}

@media (max-width: 627px) {
    .columnContainer {
        justify-content: center;
    }
}

@media (max-width: 450px) {
    .taskDetailHeadline {
        font-size: 40px;
    }

    .popupContent {
        padding: 32px 32px;
    }

    .taskDetailView span,
    .dateAndPrioContainer,
    .subtaskContainer,
    .actionButton {
        font-size: 16px;
    }

    .submitBtn {
        padding:10px 20px;
        font-size: 18px;
    }

}

@media (max-width: 380px) and (max-height: 670px) {
    .taskDetailView {
        gap: 8px;
    }
}





