* {
    font-family: 'Inter', sans-serif;
}

.sidenav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 96px;
    width: 232px;
    height: 100vh;
    background-color: #2A3647;
    padding: 64px 0;
    box-shadow: 0px 0px 4px 0px #0000001A;
}

.logo {
    width: 100px;
    
}

.sidenav nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
}

.navLink {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    padding: 8px 56px;
    color: #CDCDCD;
    font-size: 16px;
    transition: all .125s ease-in-out;
}

.navLinkActive > img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(127deg) brightness(108%) contrast(102%);
}

.navLink:hover, 
.navLinkMobile:hover {
    background-color: #091931;
    color: #fff;
}

.navLink:hover > img,
.navLinkMobile:hover > img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(127deg) brightness(108%) contrast(102%);
}


.navLink >img {
    width: 30px;
    height: 30px;
}

.sidenavMobile {
    width: 100%;
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2A3647;
    height: 80px;
    z-index: 100;
}

.sidenavMobile nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.navLinkMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #CDCDCD;
    height: 100%;
    padding: 0 8px;
    border-radius: 16px;
    gap: 8px;
    font-size: 14px;
    width: 80px;
}

.navLinkMobile > img {
    width: 24px; 
    height: 24px;
}

.navLinkActive {
    background-color: #091931;
    color: white;
}

@media (max-width: 740px) {
    .sidenav {
        display: none;
    }

    .sidenavMobile {
        display: flex;
    }
}

