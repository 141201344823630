* {
    font-family: 'Inter', sans-serif;
}

.main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F7F8;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 80px 77px 0;
}

.logo {
    width: 100px;
    height: 121.97px;
}

.signupContainer {
    display: flex;
    align-items: center;
    gap: 35px;
}

.signupContainer > span {
    font-size: 20px;
}

.signupBtn {
    padding: 15px 16px;
    border-radius: 8px;
    background-color: #2A3647;
    color: white;
    font-size: 16px;
    font-weight: 700;
}

.signupBtn:hover {
    background-color: #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190,191,192,0.75);
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 48px 115px;
    gap: 32px;
    border-radius: 30px;
    box-shadow: 0px 0px 14px 3px #0000000A;
}

.headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.seperator {
    height: 3px;
    background-color: #29ABE2;
    width: 80%;
}

.headline >h1 {
    font-size: 61px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.actionContainer {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 35px;
}

.loginBtn:hover {
    background-color: #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190,191,192,0.75);
}

.guestLoginBtn {
    cursor: pointer;
    padding: 15px 24px;
    border-radius: 8px;
    border: 1px solid #2A3647;
    font-size: 21px;
    font-weight: 700;
    color: #2A3647;
    background-color: white;
}   

.guestLoginBtn:hover {
    color: #29ABE2;
    border: 1px solid #29ABE2;
    box-shadow: 0px 6px 5px 0px rgba(190,191,192,0.75);
}

@media (max-width: 950px) {
    .main {
        gap: 24px;
    }

    .header {
        padding: 24px;
    }

    .loginContainer {
        padding: 40px 72px;
        gap: 20px;
    }

    .form {
        gap: 24px;
    }
}

@media (max-width: 500px) {
    .loginContainer {
        padding: 32px 40px;
    }

    .actionContainer {
        gap: 24px;
    }
}

@media (max-width: 445px) {
    .logo {
        width: 64px;
        height: 78.03px;
    }

    .main {
        gap: 24px;
    }

    .header {
        padding: 12px 24px 0;
    }

    .headline > h1 {
        font-size: 47px;
    }

    .loginContainer {
        padding: 24px 16px;
        width: 95%;
        gap: 12px;
    }

    .form {
        width: 100%;
    }

    .actionContainer {
        flex-direction: column;
        width: 180px;
        gap: 12px;
    }

    .guestLoginBtn {
        width: 100%;
        text-align: center;
        font-size: 18px;
        padding: 10px 24px;
    }

    .signupContainer > span {
        font-size: 18px;
    }

    .signupBtn {
        font-size: 18px;
        padding: 10px 16px;
    }
}